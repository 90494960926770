<template>
  <div>
    <b-row class="mb-2">
      <b-col sm="6">
        <go-back :title="person.name" />
      </b-col>
      <b-col sm="6" md="3" class="ml-auto">
        <b-button
          variant="primary"
          block
          :to="{ name: 'person-edit', params: { id: personId } }"
        >
          <feather-icon icon="EditIcon"/>
          {{ $t("person.actions.edit") }}
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="person">
      <b-col xs="12" md="6">
        <b-card>
          <h2>{{ person.name }}</h2>
          <span v-if="person.reference">
            <strong> Referans ID: </strong>{{ person.reference }}
          </span>

          <span v-if="person.date_of_birth" class="d-block">
            <strong>{{ $t("fields.date-of-birth") }}:</strong>
            {{ $d(new Date(person.date_of_birth * 1000), "long", "en") }}
          </span>
          <span v-if="gender" class="d-block">
            <strong>{{ $t("fields.gender") }}:</strong> {{ gender }}
          </span>
          <tag-box :tags="person.tags" />
          <b-list-group flush v-for="prop in person.properties" :key="prop.key">
            <b-list-group-item>
              {{ prop.key }}: {{ prop.value }}
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
      <b-col xs="12" md="6">
        <b-card>
          <span v-if="person.photos.length == 0">
            {{ $t("person.messages.no-photo") }}
          </span>
          <person-photo-manager
            v-else
            :editable="false"
            :initial-photos="person.photos"
            :person-id="person.id"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { show } from "@/api/people.api";
import PersonPhotoManager from "@/components/Person/PersonPhotoManager.vue";
import TagBox from "@/components/TagBox.vue";
import GoBack from "@/components/Common/GoBack.vue";

export default {
  components: { PersonPhotoManager, TagBox, GoBack },
  name: "PeopleShow",
  data() {
    return {
      person: {
        name: null,
        date_of_birth: null,
        gender: null,
        photos: [],
        properties: [],
        tags: [],
        reference: null,
      },
      loading: false,
    };
  },
  computed: {
    personId() {
      return this.$route.params?.id;
    },
    gender() {
      if (this.person?.gender) {
        return this.$t("person.gender")[this.person.gender];
      }
    },
  },
  watch: {
    personId() {
      if (this.personId) {
        this.fetchData();
      } else {
        // TODO: clear
      }
    },
  },
  methods: {
    async fetch(id) {
      this.loading = true;
      const { data } = await show(id);

      Object.keys(this.person).map((key) => {
        this.person[key] = data[key];
      });

      this.loading = false;
    },
  },
  mounted() {
    this.fetch(this.$route.params.id);
  },
};
</script>

<style>
</style>